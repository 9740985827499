



















import Vue from "vue";
import { Press } from "@/constants";
import Article from "@/components/Article.vue";

export default Vue.extend({
  name: "Press",
  components: { Article },
  data: () => ({
    Press,
  }),
});
