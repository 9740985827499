




































import Vue from "vue";

export default Vue.extend({
  name: "Article",
  props: {
    link: String,
    image: String,
    title: String,
    publication: String,
    date: Date,
    description: String,
  },
});
